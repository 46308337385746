import en from './en.json'
import Vue from 'vue';
import i18n from '../../locale/i18n';
import VeeValidate from 'vee-validate';
import enMessages from '../error-message/en'
// import hiMessages from '../error-message/hi'

export const defaultLocale = 'en'

export const languages = {
  en: en
}
VeeValidate.Validator.extend('regex_url', {
  getMessage: (field) => `The ${field} field must be url format starting with https://www. or http://www. and not contains any space.`,
  validate: value => {
    let urlRegex = new RegExp("^(http://www.|https://www.|http://|https://)?[a-z0-9]+([-.]{1}[a-z0-9]+)(.[a-z]{2,5}|.[a-z]{2,5}.[a-z]{2,5})(:[0-9]{1,5})?(/.)?$");
    return urlRegex.test(value);
  }
});
VeeValidate.Validator.extend('regex_zip_code', {
  getMessage: (field) => `The ${field} field must have 6 digit number.`,
 
  validate: value => {
    let regex_zip_code = new RegExp("^\\d{6}$");
    return regex_zip_code.test(value);
  }
});
VeeValidate.Validator.extend('regex_gst', {
  getMessage: (field) => `The ${field} field must be valid GST Number ex : (22AAAAA0000A1Z5).`,
  validate: value => {
    let gstRegex = new RegExp("^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z][0-9][Z][0-9]$");
    return gstRegex.test(value);
  }
});
VeeValidate.Validator.extend('regex_msme', {
  getMessage: (field) => `The ${field} field must be valid MSME Number ex : (AA12A1234567).`,
  validate: value => {
    let gstRegex = new RegExp("^[A-Z]{2}[0-9]{2}[A-Z][0-9]{7}$");
    return gstRegex.test(value);
  }
});

VeeValidate.Validator.extend('regex_pf', {
  getMessage: (field) => `The ${field} field must be valid PF Number ex : (APKDP12345670001234567).`,
  validate: value => {
    let pfRegex = new RegExp("^[A-Z]{5}[0-9]{7}[A-Z0-9]{3}[0-9]{7}$");
    return pfRegex.test(value);
  }
});
VeeValidate.Validator.extend('regex_cin', {
  getMessage: (field) => `The ${field} field must be valid CIN Number ex : (U12345AA1234AAA123456).`,
  validate: value => {
    let cinRegex = new RegExp("^([L|U]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$");
    return cinRegex.test(value);
  }
});
VeeValidate.Validator.extend('regex_account_number', {
  getMessage: (field) => `The ${field} field must contains only Numbers.`,
  validate: value => {
    let accountnumberRegex = new RegExp("^[0-9]*$");
    return accountnumberRegex.test(value);
  }
});
VeeValidate.Validator.extend('regex_Email', {
  getMessage: (field) => `The ${field} field must be a valid E-mail.`,
  validate: value => {
    let emailRegex = new RegExp("^[a-z0-9][a-z0-9_\.-]{0,}[a-z0-9]@[a-z0-9][a-z0-9_\.-]{0,}[a-z0-9][\.][a-z0-9]{2,4}$");
    return emailRegex.test(value);
  }
});
VeeValidate.Validator.extend('regex_duns', {
  getMessage: (field) => `The ${field} field must have 9 digits`,
  validate: value => {
    let dunsRegex = new RegExp("^[0-9]{9}$");
    return dunsRegex.test(value);
  }
});
VeeValidate.Validator.extend('regex_mobile_number', {
  getMessage: (field) => `The ${field} field must contain only 10 digits`,
  validate: value => {
    let mobile_number_Regex = new RegExp("^[1-9][0-9]{9}$");
    return mobile_number_Regex.test(value);
  }
});
VeeValidate.Validator.extend('regex_mobile_register', {
  getMessage: (field) => `The ${field} field Starting Number should not be 0.`,
  validate: value => {
    let mobile_number_Regex = new RegExp("^(|[1-9][0-9]*)$");
    return mobile_number_Regex.test(value);
  }
});
VeeValidate.Validator.extend('regex_userName', {
  getMessage: (field) => `${field} can have uppercase, lowercase and special characters (only \`-\` and \`_\`) and without any spaces`,
  validate: value => {
    let regex_userName_validation = new RegExp(`^[a-zA-Z0-9-_]+$`);
    return regex_userName_validation.test(value);
  }
});
VeeValidate.Validator.extend('regex_password', {
  getMessage: (field) => `The ${field} must contain at least 8 characters, lowercase and uppercase characters, a number, and at least 1 special character(!,@,#,$)`,
  validate: value => {
    let regex_password_validation = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!#])[A-Za-z0-9@$!#]{8,20}$");
    return regex_password_validation.test(value);
  }
});
VeeValidate.Validator.extend('regex_percentage', {
  getMessage: (field) => `The ${field} field must be within 100%`,
  validate: value => {
    let regex_percentage_validation = new RegExp(`^100$|^[0-9]{1,2}$|^[0-9]{1,2}\,[0-9]{1,3}$`);
    return regex_percentage_validation.test(value);
  }
});
VeeValidate.Validator.extend('regex_ifsc', {
  getMessage: (field) => `The ${field} must only contain numeric 7 characters and a valid IFSC code`,
  validate: value => {
    let regex_ifsc_validation = new RegExp(`^[0][0-9]{6}$`);
    return regex_ifsc_validation.test(value);
  }
});
VeeValidate.Validator.extend('regex_finance_ifsc', {
  getMessage: (field) => `The ${field} field must be valid IFSC Code ex : (AAAA0123456).`,
  validate: value => {
    let regex_ifsc_validation = new RegExp(`^[A-Z]{4}[0][0-9]{6}$`);
    return regex_ifsc_validation.test(value);
  }
});
// Extended custom rules for number
VeeValidate.Validator.extend('regex_number', {
  getMessage: (field) => `The ${field} must only contain numeric characters and may start with -.`,
  validate: value => {
    let numberRegex = new RegExp("^[-]?[0-9]*\d{0,2}$");
    return numberRegex.test(value);
  }
});
VeeValidate.Validator.extend('regex_number_noneg', {
  getMessage: (field) => `The ${field} must only contain numeric characters.`,
  validate: value => {
    let numberRegex = new RegExp("^[0-9]*\d{0,2}$");
    return numberRegex.test(value);
  }
});
// VeeValidate.Validator.extend('regex_approxnumber', {
//   getMessage: (field) => `The ${field} must not start with 0`,
//   validate: value => {
//     let approxnumberRegex = new RegExp("(^[1-9][0-9]{0,}$)|(^[0][.](([0][1-9])$|([1-9][0-9]{0,1})$))|(^[1-9][0-9]{0,}[.](([0][1-9]$)|([1-9][0-9]{0,1}$)))");
//     return approxnumberRegex.test(value);
//   }
// });
VeeValidate.Validator.extend('regex_negnumber', {
  getMessage: (field) => `The ${field} must not contains Negative Value and only Number allowed.`,
  validate: value => {
    let numbernegRegex = new RegExp("^[0-9]*\d{0,2}$");
    return numbernegRegex.test(value);
  }
});
VeeValidate.Validator.extend('regex_creditrating', {
  // getMessage: (field) => `The ${field} must follow the format (AA or AA+ or AA- or AA1+ or AA1- or AAA+ or AAA- or AAA1+ or AAA1-).`,
  getMessage: (field) => `The ${field} must contains capital Alphabetic and no spaces (may include Numbers and '-' or '+' at last) or '0'.`,
  validate: value => {
    let creditRatingRegex = new RegExp("(^[A-Z]{1,}[1-9]{1,}?[-+]$)|(^[A-Z]{1,}?[1-9]{1,}$)|(^[A-Z]{1,}$)|(^[A-Z]{1,}?[-+]$)|(^[0]$)");
    return creditRatingRegex.test(value);
  }
});
VeeValidate.Validator.extend('regex_alphacaps', {
  getMessage: (field) => `The ${field} field must only contain capital alphabetic characters and no spaces.`,
  validate: value => {
    let alphacapsRegex = new RegExp("^[A-Z]{1,}$");
    return alphacapsRegex.test(value);
  }
});
VeeValidate.Validator.extend('regex_numless33', {
  getMessage: (field) => `The ${field} field must Number and must less than 33.`,
  validate: value => {
    let alphacapsRegex = new RegExp("^[A-Z]{1,}$");
    return alphacapsRegex.test(value);
  }
});
VeeValidate.Validator.extend('regex_percentage_decimal', {
  getMessage: (field) => `The ${field} field must not greater than 100.`,
  validate: value => {
    let regex_percentage = new RegExp("(^(100)$|^(100[.][0]{1,})$)|(^[0-9]{1,2}?[.][0-9]{1,}$)|(^[0-9]{1,}$)");
    return regex_percentage.test(value);
  }
});
VeeValidate.Validator.extend('regex_alpha_special', {
  getMessage: (field) => `The ${field} field must only contain alphabetic characters and special characters.`,
  validate: value => {
    let regex_percentage = new RegExp("^([a-zA-Z !@#\$%\^\&*\)\(+=._-]+$)");
    return regex_percentage.test(value);
  }
});
VeeValidate.Validator.extend('regex_alpha_numeric_special', {
  getMessage: (field) => `The ${field} field must only contain alphabetic characters, numbers and special characters.`,
  validate: value => {
    let regex_percentage = new RegExp("^[A-Za-z0-9 !@\n#\$%\^\&*\)\(+=._-]*$");
    return regex_percentage.test(value);
  }
});
VeeValidate.Validator.extend('regex_alpha_numeric_space', {
  getMessage: (field) => `The ${field} field must only contain alphabetic characters, numbers and space.`,
  validate: value => {
    let regex_percentage = new RegExp("^[a-zA-Z0-9 ]*$");
    return regex_percentage.test(value);
  }
});
VeeValidate.Validator.extend('regex_number_decimal', {
  getMessage: (field) => `The ${field} must only contain numeric characters ,Decimal values and may start with -.`,
  validate: value => {
    let numberRegex = new RegExp("^[-]?[0-9]+(\.([0-9]{1,})?)?$");
    return numberRegex.test(value);
  }
});
VeeValidate.Validator.extend('regex_warehouse_name', {
  getMessage: (field) => `The ${field} must contain more than one letters and less than forty letters and consists of alphanumeric characters`,
  validate: value => {
    let numberRegex = new RegExp("^[a-zA-Z0-9_ ]{1,40}$");
    return numberRegex.test(value);
  }
});
// Use for  lange change for validation error message
Vue.use(VeeValidate, {
  i18n,
  fieldsBagName: 'formFields',
  locale: defaultLocale,
  dictionary: {
    en: {
      messages: enMessages
    },
    // hi: {
    //   messages: hiMessages
    // },
  }
});