<template>
  <b-modal
    v-model="trigger"
    :id="id"
    :ok-title="okTitle"
    :title="title"
    @ok="eventOk($event)"
    @cancel="eventCancel($event)"
    :centered="centered"
    :no-close-on-backdrop="dontCloseWhenClickedOutside"
    :no-close-on-esc="dontCloseWhenEscapePressed"
    :hide-header-close="dontShowCloseIcon"
    :ok-only="okOnly"
    :hide-footer="true"
    :hide-header="true"
  >
    <div class="modal-close">
      <span class="Modal-head-text" v-if="!isSchedulingTime">{{ title }}</span>
      <p class="mt-2 mx-3">
        <span class="schedule-time" v-if="isSchedulingTime">Auto Scheduling Time</span>
        <img
          @click="closeEmpanelModal()"
          class="cursor-pointer modal-close-button mt-n1"
          style="float:right"
          src="@/assets/CloseModel.svg"
        />
      </p>
    </div>
  <!-- view MTR table for GST start -->
    <div class="p-3" style="max-height:550px;overflow:auto;font-size:14px" v-if="isViewMtrs">
      <table class="table table-striped">
        <tbody>
          <tr>
            <td>Total CGST</td>
            <td>{{viewModalTableData.currencySymbol}} {{ viewModalTableData.totalCgst }}</td>
          </tr>
          <tr>
            <td>Total IGST</td>
            <td>{{viewModalTableData.currencySymbol}} {{ viewModalTableData.totalIgst }}</td>
          </tr>
          <tr>
            <td>Total SGST</td>
            <td>{{viewModalTableData.currencySymbol}} {{ viewModalTableData.totalSgst }}</td>
          </tr>
          <div class="mt-4" v-if="isPermission('allow_to_download_mtr_report')">
            <span style="color:#2e8cd3; cursor:pointer" @click="downloadViewMTR()"> Download Cancelled Order invoices </span>
          </div>
        </tbody>
      </table>
    </div>
<!-- view MTR table for GST End -->

    <div class="p-3" style="max-height:550px;overflow:auto;font-size:14px" v-if="showModalTable">
      <table class="table table-striped">
        <tbody v-if="showEasyShip">
          <tr v-for="(value, key) in modalTableDetails.easyShipCharges" :key="key">
            <td>{{ key }}</td>
            <td>
              <span>
                <span v-if="value!=null">{{ modalCurrency }}</span>
                {{ value }}
              </span>
            </td>
          </tr>
        </tbody>
        <tbody v-if="showCancellation">
          <tr v-for="(value, key) in modalTableDetails.cancellationCharges" :key="key">
            <td>{{ key }}</td>
            <td>
              <span>
                <span v-if="value!=null">{{ modalCurrency }}</span>
                {{ value }}
              </span>
            </td>
          </tr>
        </tbody>
        <tbody v-if="!showEasyShip && !showCancellation">
          <tr v-for="(value, key) in modalTableDetails.priceTypeAmount" :key="key">
            <td>{{ key }}</td>
            <td>
              <span>
                <span v-if="value!=null">{{ modalCurrency }}</span>
                {{ value }}
              </span>
            </td>
          </tr>
          <tr v-for="(value, key) in modalTableDetails.orderItemFeeMap" :key="key">
            <td>{{ key }}</td>
            <td>
              <span>
                <span v-if="value!=null">{{ modalCurrency }}</span>
                {{ value }}
              </span>
            </td>
          </tr>
        </tbody>
<!-- service fee -->
        <tbody v-if="isServicefee">
          <tr v-for="(value, key) in modalTableDetails.tableData" :key="key">
            <td>{{ key }}</td>
            <td>
              <span>
                <span v-if="value!=null">{{ modalCurrency }}</span>
                {{ value }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="p-3" v-if="alert">
      <div class="row m-4 align-class">
        <img
          :style="getIconStyle(alertType)"
          v-if="alertType === 'Success'"
          :src="getImage(alertType)"
        />
        <img
          v-if="alertType === 'Failure'"
          :style="getIconStyle(alertType)"
          :src="getImage(alertType)"
        />
      </div>
      
      <div class="row m-4 mb-5 align-class">
        <p class="alert-msg m-0">{{ alertMsg }}</p>
      </div>
      <div class="row mb-1 justify-content-center" v-if="isConfirmAlert">
        <w-button-input 
          :buttonStyle="'generate-btn'" 
          :buttonClass="'generate-btn role-btn'"
          :label="'Confirm'"
          @buttonClicked="confirmOk"
        />
        <w-button-input 
          class="ml-5"
          :buttonStyle="'request-demo-style'" 
          :buttonClass="'cancel-button role-btn cancel-btn'"
          :label="'Cancel'"
          @buttonClicked="cancel"
        />
      </div>
    </div>

    <!-- pickup detail for selfship when we click VIEW link isPickUpDetail -->
    <div class="p-3" style="overflow:auto;font-size:14px" v-if="isPickUpDetail">
      <table class="table table-striped">
        <tbody>
          <tr>
            <td>Pickup Status</td>
            <td>{{pickUPDetails.pickUpStatus}}</td>
          </tr>
          <tr>
            <td>Pickup Scheduled Date</td>
            <td>{{pickUPDetails.pickUpScheduledDate}}</td>
          </tr>
          <tr>
            <td>Pickup Token Number</td>
            <td>{{pickUPDetails.pickUpTokenNumber}}</td>
          </tr>
          <tr>
            <td>Pickup Generated Date</td>
            <td>{{pickUPDetails.pickUpGeneratedDate}}</td>
          </tr>
          <tr>
            <td>Detail</td>
            <td>{{pickUPDetails.pickupUpDetails}}</td>
          </tr>
          <tr>
            <td>AWB</td>
            <td>{{pickUPDetails.awbNumber}}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- add a scheduling pickup time for add channel -->
    <div class="p-3" v-if="isSchedulingTime">
      <div class="d-flex justify-content-center">
        <date-picker
          id="scheduleTime"
          v-model="selectScheduleTime"
          lang="en"
          format="HH"
          type="time"
          :hour-options="hours"
          :minute-step="61"
          placeholder="Select Time"
        ></date-picker>
      </div>
      <div class="d-flex justify-content-center mt-4 mb-2">
        <w-button-input 
          :buttonStyle="'generate-btn'" 
          :buttonClass="'generate-btn role-btn'"
          :label="'Save'"
          @buttonClicked="saveSchedulingTime"
        />
        <w-button-input 
          class="ml-4"
          :buttonStyle="'request-demo-style'" 
          :buttonClass="'cancel-button role-btn cancel-btn'"
          :label="'Cancel'"
          @buttonClicked="cancel"
        />
      </div>
    </div>

  </b-modal>
</template>
<script>
import InputButton from "../widgets/InputButton.vue";
import DatePicker from "vue2-datepicker";
import { isAuthorized } from "../Service/CommonService";

export default {
  components:{
    "w-button-input": InputButton,
    DatePicker
  },
  props: [
    "trigger",
    "text",
    "title",
    "centered",
    "dontCloseWhenClickedOutside",
    "dontCloseWhenEscapePressed",
    "dontShowCloseIcon",
    "modalbuttonStyle",
    "modalTableDetails",
    "showModalTable",
    "alert",
    "alertMsg",
    "alertType",
    "isConfirmAlert",
    "showEasyShip",
    "modalCurrency",
    "showCancellation",
    "isServicefee",
    "isViewMtrs",
    "viewModalTableData",
    "isPickUpDetail",
    "pickUPDetails",
    "isSchedulingTime",
  ],
  data(){
    return {
      selectScheduleTime:"",
    }
  },
  methods: {
    // Permission based validation
    isPermission(key){
      return isAuthorized(key)
    },

    
    eventOk(event) {
      this.$emit("eventOk", event);
    },
    closeEmpanelModal(event) {
      this.$emit("eventClose", event);
    },
    downloadViewMTR(event) {
      this.$emit("downloadMTRView", event);
    },
    getIconStyle(alert) {
      switch (alert) {
        case "Success":
          return "background-color: #47d764;border-radius: 100%; padding: 2px;";
        case "Failure":
          return "height:30px;width:30px";
      }
    },
    confirmOk() {
      this.$emit("confirmOkEvent");
    },
    cancel() {
      this.$emit("eventClose");
    },
    getImage(image) {
      var images = require.context("../assets/alert-msg/", false, /\.svg$/);
      return images("./" + image + ".svg");
    },
    saveSchedulingTime(){
      this.$emit("saveSchedulingTimeEvent", this.selectScheduleTime)
    },

  }
};
</script>

<style>
.Modal-head-text {
  color: white;
  font-size: 13px;
  float: left;
}
.modal-content {
  width: 100%;
  border-radius: 10px !important;
}
.modal-close {
  padding: 6px !important;
  color: #fff;
  /* background: #0071c1; */
}
.modal-body {
  padding: 0;
}
.modal-close-button {
  border: solid 1px #707070;
  color: rgba(0, 0, 0, 0.85);
  border-radius: 50%;
  background-color: rgb(255, 255, 255) !important;
  margin: 0.5px 0px 0px 5px !important;
  width: 18px;
  cursor: pointer;
}
.modal-backdrop {
  opacity: 0.5 !important;
}
.align-class {
  display: flex;
  align-items: center;
  justify-content: center;
}
.alert-key {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  text-align: left;
  color: #191d24;
}
.alert-msg {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: 0.36px;
  text-align: left;
  color: #898b91;
}
.schedule-time{
  color:#1b1c1d; 
  font-size:18px; 
  font-weight:600;
}
</style>
