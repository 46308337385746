<template>
  <div v-if="isValidAuth()">
    <!-- <div class="rectangle-1" style="position:fixed; z-index:100001;">
      <h4 class="logo m-3" style="color:skyblue">Ecommerce</h4>
      <h4 class="mb-0 mr-4" style="cursor:pointer">
        <img src="../assets/add.png" @click="showSideTable()">
      </h4>
    </div> -->
    <vue2-sidebar heading="hhh" :links="links">
      <slot />
    </vue2-sidebar>

<!-- side table details when we click on right side icon -->
    <table id="sideTable" v-if="isSideTableShow">
      <tr>
        <th>Operation</th>
        <th style="display:flex;justify-content:space-between">
          <span>Date and Time</span> 
          <span class="crossIcon" @click="closeShowSideTable()">
            <img src="../assets/CloseModel.svg">
            </span>
        </th> 
      </tr>
      <tr>
        <td>Order Sync</td>
        <td></td>
      </tr>
      <tr>
        <td>Inventory Sync</td>
        <td></td>
      </tr>
      <tr>
        <td>Remittance Sync</td>
        <td></td>
      </tr>
    </table>

  </div>
</template>
<script>
import Vue2Sidebar from "../components/Common/Vue2Sidebar.vue";
import { isAuthorized } from '../Service/CommonService';
// import NavBar from "./NavBar.vue";

export default {
  components: {
    "vue2-sidebar": Vue2Sidebar,
    // NavBar
  },
  created(){
    sessionStorage.setItem("companyIds",7)
  },

  data() {
    return {
      isSideTableShow:false,

      links: [
        {
          label: "Dashboard",
          iconName: "dashboard",
          activeIcon:"DashboardHigh",
          to: "/dashboard",
          hidden:true
        },
        {
          label: "Orders",
          iconName: "orders",
          activeIcon:"OrderHigh",
          to: "/order",
          hidden:true,
        },
        {
          label: "Shipments",
          iconName: "delivery",
          activeIcon:"ShipmentHigh",
          to: "/shipment",
          hidden:true,
        },
        {
          label: "Inventory",
          iconName: "inventory",
          activeIcon:"InventoryHigh",
          to: "/inventory",
          hidden:true,
        },
        {
          label: "Channels",
          iconName: "channels",
          activeIcon:"ChannelsHigh",
          to: "/ViewChannel",
          hidden:true,
        },
        {
          label: "Warehouses",
          iconName: "Warehouse",
          activeIcon:"warehouseHigh",
          to: "/warehouse",
          hidden:true,
        },
        {
          label: "Remittance",
          iconName: "remittances",
          activeIcon:"RemittancesHigh",
          to: "/remittance",
          hidden:true,
        },
        {
          label: "Reports",
          iconName: "report",
          activeIcon:"ReportsHigh",
          to: "/report",
          hidden:true,
        },
        {
          label: "GST",
          iconName: "GST",
          activeIcon:"GSTHigh",
          to: "/gst",
          hidden:true,
        },
        {
          label: "Users",
          iconName: "user",
          activeIcon:"UserHigh",
          to: "/user",
          hidden:true
        },
         {
          label: "Customers",
          iconName: "CustomerLight",
          activeIcon:"Customer",
          to: "/customers",
          hidden:true,
        },
      ],
    };
  },

  created(){
    this.showDashboardTabs();
  },

  methods: {
    // validate the tabs
    showDashboardTabs(){
      let item = [];
      
      if(this.isPermission('allow_dashboard')){
        item.push('Dashboard') 
      }
      if(this.isPermission('allow_order_management')){
        item.push('Orders') 
      }
      if(this.isPermission('allow_shipment_management')){
        item.push('Shipments')
      }
      if(this.isPermission('allow_inventory_management')){
        item.push('Inventory')
      }
      if(this.isPermission('allow_channel_management')){
        item.push('Channels')
      }
      if(this.isPermission('allow_warehouse_management')){
        item.push('Warehouses')
      }
      if(this.isPermission('allow_remittance')){
        item.push('Remittance')
      }
      if(this.isPermission('allow_reports')){
        item.push('Reports')
      }
      if(this.isPermission('allow_mtr')){
        item.push("GST")
      }
      if(this.isPermission('allow_users') || (this.isPermission('allow_manage_own_company'))){
        item.push('Users')
      }
      if(this.isPermission('allow_customers')){
        item.push('Customers')
      }
      
      this.links.map((link,index) =>  {  
        if(item.includes(link.label)){
          link.hidden = true;
          // this.links.splice(index,1);
        }else{
          link.hidden=false;
        }
      })
      this.goToFirstPage()
    },
    goToFirstPage(){
      // this.$router.push(this.links[0].to);
      if(this.$route.path != this.links[0].to){
        this.$router.push(this.links[0].to);
      }
    },
    isPermission(key){
      return isAuthorized(key)
    },



    isValidAuth() {
      //if invalid, server will retur 401 and it will be automatically redirect to login page, so returning true here
      return true;
    },

    showSideTable(){
      this.isSideTableShow = true;
    },
    closeShowSideTable(){
      this.isSideTableShow = false;
    }

  },
};
</script>

<style scoped>
/* .logo {
  width: 68px;
  height: 29px;
  font-family: "proxima-nova", sans-serif !important;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: center;
  color: #1b1c1d;
} */
/* .rectangle-1 {
  width: 100%;
  height: 55px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;

  display: flex;
  justify-content: space-between;
  align-items: center;
} */

/* table detail */
table {
  width:30%;
  position: fixed;
  right: 0%;
  top: calc(8% - 4px);
  z-index: 999999;
  border: 1px solid #0071c1;
}
th{
  font-size: 16px !important;
}
th, td {
  padding: 15px;
  text-align: left;
  font-size: 12px;
}
#sideTable tr:nth-child(even) {
  background-color: #eee;
}
#sideTable tr:nth-child(odd) {
 background-color: #fff;
}
#sideTable th {
  background-color: #0071c1;
  color: white;
}
#sideTable .crossIcon{
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
}
#sideTable .crossIcon img{
  width: 20px;
  height: 20px;
  padding-bottom: 5px;
}
</style>
