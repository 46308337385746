import { Apihelper } from "./API/apiHelper.js";
const apiHelper = new Apihelper();
const channelBaseUrl = process.env.VUE_APP_CHANNEL_SERVER_URL;
const registerBaseUrl = process.env.VUE_APP_REGISTRATION_SERVER_URL;
import axios from "axios";
// Common api for channel list
export const getChannelList = () => {
  const uri = `${channelBaseUrl}/api/v1/companies/channels`;  
  return apiHelper.get(uri);
};

export const getUserList = () => {
  let userList = [];
  userList.push({ value: null, text: "Select Created By" });
  userList.push({ value: 0, text: "Suma SB" });
  userList.push({ value: 1, text: "Imran" });
  userList.push({ value: 2, text: "Gaurav" });
  userList.push({ value: 3, text: "Uday" });
  return userList;
};

export const getCountryOptions = () => {
  const uri = `${registerBaseUrl}/api/v1/country-list`;
  return apiHelper.get(uri);
};

export const getStateOptions = (countryCode) => {
  const uri = `${registerBaseUrl}/api/v1/${countryCode}/state-list`;
  return apiHelper.get(uri);
};

export const getCityOptions = (countryCode) => {
  const uri = `${registerBaseUrl}/api/v1/${countryCode}/city-list`;
  return apiHelper.get(uri);
};

export const getCountriesList = () => {
  return apiHelper.getCountryList();
}

export const getLocation = (zipCode, countryCode) => {
  // const uri = `maps/api/geocode/json?address=${zipCode}&region=${countryCode}&key=${GOOGLE_MAPS_API_KEY}&components=country:${countryCode}`
  const uri = `https://api.worldpostallocations.com/pincode?postalcode=${zipCode}&countrycode=${countryCode}`
  return axios.get(uri);
}

export const isAuthorized = (key) => {
  // let permissionKey = key;
  // let permissionKList = JSON.parse(atob(sessionStorage.getItem('token').split('.')[1])).authorities ? JSON.parse(atob(sessionStorage.getItem('token').split('.')[1])).authorities : []; // Decoding the token to get permissions of the user
  // if(permissionKList.length > 0){
  //   return permissionKList.indexOf(permissionKey) > -1 ? true : false;
  // }else{
  //   return false;
  // }
  
  // TODO: Commented out above code and added below line to disable roles - permissions feature temporarily. Need to re-enable this later.
  return true;
}