<template>
  <div class="hecto-nav">
    <div class="row  p-2 m-0">
      <div class="col-3 nav-app-logo">
        <!-- <h1>LOGO</h1> -->
        <img src="../assets/nav/HectocommerceLogo.svg">
      </div>
      <div class="col-4 m-0 ml-auto">
          <div class="user-icon p-0">
            <!-- {{comapnyLogo}} -->
            <avatar v-if="companyDetail && companyLogoUrl" :username="companyDetail.companyName" size="35" :src="validateImageUrl(companyLogoUrl)"></avatar>
          </div>
          <div class="organization-name pointer" @click="goToProfile()">{{companyDetail.companyName}}</div>
          <div class="user-icon pointer mr-5 p-0" @click="showProfileCard()" @blur="showProfileCard()">
            <!-- {{userLogo}} -->
            <avatar v-if="userDetails && profileLogoUrl" :username="userDetails.firstName+' '+userDetails.lastName" size="35" :src="validateImageUrl(profileLogoUrl)"></avatar>
          </div>
          <div :class="isProfileCard?'d-block':'d-none'" class="profile-pop-up" >
            <div class="user-icon profile-user-icon p-0">
              <!-- {{comapnyLogo}} -->
              <avatar v-if="companyDetail && companyLogoUrl" :username="companyDetail.companyName" size="50" :src="validateImageUrl(companyLogoUrl)"></avatar>
            </div>
            <div class="profile-organization-name organization-name" >{{companyDetail.companyName}}</div>
            <a class="user-detail-link" @click="goToProfile()">View Company / User Profile</a>
            <hr class="divider-line" />
            <div class="user-section-wrapper">
              <div class="user-icon profile-user-icon ml-0 mr-2 p-0">
                <!-- {{userLogo}} -->
                <avatar v-if="userDetails && profileLogoUrl" :username="userDetails.firstName+' '+userDetails.lastName" size="50" :src="validateImageUrl(profileLogoUrl)"></avatar>
              </div>
              <div class="user-section">
                <p class="user-name user-position">{{userDetails.firstName}} {{userDetails.lastName}}</p>
                <p class="user-mail">{{userDetails.email}}</p>
                <p class="user-name user-position" style="color:grey;font-size:14px">Company Administrator</p>
              </div>
            </div>
            <hr class="divider-line" />
            <w-input-button 
              :buttonClass="'auth-button profile-button'"
              :buttonStyle="'auth-button-style'"
              @buttonClicked="logoutButton()"
              :disabled="isloginDetail"
              :label="'Logout'"
            />
            <w-input-button 
              class="mt-2"
              :buttonClass="'request-demo profile-button logout-btn'"
              :buttonStyle="'request-demo-style'"
              :label="'Change Password'"
              @buttonClicked="changePassword()"
            />
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from 'vue-avatar'
import InputButton from "../widgets/InputButton.vue";
// import { getCompanyDetails } from "../Service/UserService.js";

export default {
  components:{
    Avatar,
    "w-input-button":InputButton
  },
  props:["companyDetail","comapnyLogo","userLogo","userDetails", "companyLogoUrl", "profileLogoUrl"],

  created(){
    // this.getCompanyDetail()
  },

  data(){
    return{
      isProfileCard:false,
      // companyDetail:{},
      // comapnyLogo:""
    }
  },

  methods:{
    showProfileCard(){
      this.isProfileCard = !this.isProfileCard
    },
    // getCompanyDetail(){
    //   getCompanyDetails().then(res=>{
    //     this.companyDetail = res;
    //     if(this.companyDetail.logoUrl === null){
    //       let logo = this.companyDetail.companyName.split(" ");
    //       this.comapnyLogo = logo[0][0] + logo[1][0];
    //     }
    //   })
    // },
    logoutButton(){
      sessionStorage.clear();
      localStorage.clear();
      this.$router.push("/");
    },
    goToProfile(){
      this.$router.push("/profile")
      this.isProfileCard = false
    },
    changePassword(){
      this.$router.push("/changePassword");
    },
    validateImageUrl(logoUrl) {
      // TODO: Need to move this function to a common utils file.
      let subStrings = ['.jpeg', '.jpg', '.png', '.gif', '.ico'];
      if (subStrings.some(v => logoUrl.includes(v))) {
        return logoUrl;
      }
      return '';
    }

  }
}
</script>

<style scoped>
.hecto-nav{
    height: 65px;
    width: 100vw;
    background-color: #FFFFFF;
    position: fixed;
    left: 0;
    z-index: 10;
    box-shadow: 0px 1px 2px #00000033;
}
.hecto-nav > .row > .col-4{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.hecto-nav > .nav-app-logo{
  font-size: 24px;
  color: #1B1C1D;
  /* font-family: ''; */
}
.organization-name{
    color: #1b1c1d;
    font-size: 16px;
    font-family: proxima-nova,sans-serif;
    font-weight: 700;
    border: 1px solid #b5b5b5;
    border-top: 0;
    border-bottom: 0;
    border-left: 0;
    margin: 0 20px 0px 10px;
    padding-right: 20px;
    white-space: nowrap;
}
.profile-organization-name{
    border: 0px;
    padding: 0;
    margin: 0;
    margin-top: .8rem;

}
.user-icon{
    background-color: #7d9ffc;
    padding: 9px;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 800;
    color: #FFFFFF;
    flex-direction: column;
}

.profile-pop-up{
    position: absolute;
    top: 62px;
    background-color: #ffffff;
    /* height: 18rem; */
    width: 18rem;
    border-radius: 10px;
    text-align: center;
    align-items: center;
    padding: 1.5rem;
    padding-bottom:8px;
    right: 13%;
    box-shadow: 0px 1px 10px #00000040;
}
.user-detail-link{
  font-size: 12px;
  color: #2185D0;
  cursor: pointer;
}
.profile-user-icon{
  background-color: #6c87cf;
  width: 50px;
  height: 50px;
  margin: 0 auto;
  padding: 12px;
}
.divider-line{
  width: 30px;
  margin: 1rem auto;
  border-width: 2px;
}
.user-section-wrapper{    
  align-items: center;
  display: flex;
  justify-content: center;
}
.user-section{
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 10px;
}
.user-name{
  color:#1B1C1D;
  font-size: 16px;
  margin: 0;
}
.user-mail{
  color:#555656;
  font-size: 12px;
  margin: 0;
}
.user-position{
  font-weight: bold;
  margin: 0;
}
.profile-button {
  max-width: none !important;
}
.logout-btn {
  color: #2185D0;
}
</style>