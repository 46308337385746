import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
// import VueRouter from 'vue-router'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
// import $ from 'jquery'
import Sidebar from "./Layouts/Sidebar.vue"
import NoSideBar from "./Layouts/NoSideBar.vue"
import router from "./routes/index.js"
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { FormInputPlugin } from "bootstrap-vue";
import Vuelidate from 'vuelidate'
import i18n from "@/locale/i18n";
// highcharts
import HighchartsVue from 'highcharts-vue'
import Highcharts from 'highcharts'
import stockInit from 'highcharts/modules/stock'
import VueTelInput from "vue-tel-input";
import { ValidationProvider } from 'vee-validate';
import { ValidationObserver } from 'vee-validate';

import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import { VueReCaptcha } from 'vue-recaptcha-v3';
import * as VueGoogleMaps from "vue2-google-maps";

Vue.component('VueSlickCarousel', VueSlickCarousel);

// Text Title Case Util Function
Vue.prototype.$toTitle = function(text) {
  return text.toLowerCase().replace(/(^|\s)\S/g, function(t) { return t.toUpperCase() });
}

//Vue.use(VueReCaptcha, { siteKey: '6LdNrIUaAAAAAB3ggr4Hjs8LS04hJqSWpgF0gYVL' }) 6LfD2YUaAAAAALfwtsH9nVY4JH9k96fjjXyUYJZf
//localhost enable     6LcYooQaAAAAADYt4hUP35IBJTi9O0BHiK8sAMoN
Vue.use(VueReCaptcha, { siteKey: '6LcYooQaAAAAADYt4hUP35IBJTi9O0BHiK8sAMoN' })

// import RefreshToken from "./Service/helper/RefreshToken";
// Register it globally
// main.js or any entry file.
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
stockInit(Highcharts)
Vue.use(HighchartsVue)
// Vue.use(RefreshToken);

Vue.use(VueTelInput);
// Vue.use(VueRouter);
Vue.use(FormInputPlugin)
Vue.component("sidebar-layout", Sidebar);
Vue.component("no-sidebar-layout", NoSideBar)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    libraries: "places", // necessary for places input
    region: process.env.DEFAULT_GOOGLE_PLACE_REGION,
    language: "en",
    callback: "initEditClntInfoAutoComplete",
    type: "regions"
  }
});
Vue.use(Vuelidate)
export const EventBus = new Vue();

Vue.config.silent = true;
Vue.config.productionTip = false

new Vue({
  vuetify,
  i18n,
  router,
  render: h => h(App)
}).$mount('#app')
