<template>
  <div
    :is="isRoute ? 'router-link' : 'a'"
    :href="link.href"
    :to="link.to"
    :replace="link.replace"
    :class="
      $route.meta.breadcrumb && $route.meta.breadcrumb.parent === link.label
        ? 'active'
        : 'parent-active'
    "
    :exact-active-class="'active'"
  >
    <img :src="menuIconUrl(link.activeIcon)" class="m-auto d-none activeIcon" />
    <img :src="getMenuIconUrl(link.iconName)" class="m-auto d-block iconName" />
    <p class="mb-0 link-label" style="width: 100%; font-size:12px; ">{{ link.label }}</p>
  </div>
</template>

<script>
export default {
  props: ["link"],
  methods: {
    getMenuIconUrl(iconName) {
      let images = require.context("../../assets/", false, /\.svg$/);
      return images("./" + iconName + ".svg");
    },
    menuIconUrl(activeIcon) {
      let images = require.context("../../assets/DarkIcon", false, /\.svg$/);
      return images("./" + activeIcon + ".svg");
    },
  },
  computed: {
    isRoute() {
      return this.$router && (this.link.to || this.link.component);
    },
  },
};
</script>
<style scoped>
/* @media only screen and (max-width: 768px){
  .activeIcon{
    display: none !important;
  }
  .iconName{
    display: block !important;
  }
} */
.link-label{
  display: none;
  color: black;
}
@media screen and (max-width:500px) {
  
.link-label{
  display: block;
  color:#ffffff;
}
.activeIcon{
  width: 20px;
  margin-bottom: 5px !important;
}
.iconName{
  width: 20px;
  margin-bottom: 5px !important;
}
}
</style>
