import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { languages } from '../assets/i18n/index.js'
import { defaultLocale } from '../assets/i18n/index.js'

Vue.use(VueI18n);

const messages = Object.assign(languages);

const i18n = new VueI18n({
    locale: defaultLocale, // set locale
    fallbackLocale: 'en', // set fallback locale
    messages, // set locale messages
    silentTranslationWarn: true
});


export default i18n; 