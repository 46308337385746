import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import colors from 'vuetify/es5/util/colors'

Vue.use(Vuetify)

export default new Vuetify({
    iconfont: 'mdi',
    theme: {
        primary: colors.green.darken1,
        secondary: colors.green.lighten4,
        accent: colors.green.darken3
    }
})