import { Apihelper } from "./API/apiHelper.js";
const apiHelper = new Apihelper();
const loginUrl = process.env.VUE_APP_LOGIN_SERVER_URL;
const accountUrl = process.env.VUE_APP_USER_SERVER_URL; 

export const login = (data) => {
    const uri = `${loginUrl}/auth/login`;
    return apiHelper.post(uri, data);
}

export const refreshToken = (data) => {
    const uri = `${loginUrl}/refresh-token`;
    return apiHelper.post(uri, data);
}

// for reset password
export const resetPasswordInit = (data) => {
    const uri = `${accountUrl}/account/reset-password/init`;
    return apiHelper.post(uri, data);
}

// for submit reset password
export const submitResetPassword = (data) => {
    const uri = `${accountUrl}/account/reset-password/finish`;
    return apiHelper.post(uri, data)
}

// Is user logged in
export const isUserAuth = () => {
    const uri = `${accountUrl}/authenticate`;
    return apiHelper.get(uri);
}

// change password
export const changePassword = (data) => {
    const uri = `${accountUrl}/account/change-password`;
    return apiHelper.post(uri, data);
}