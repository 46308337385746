<template>
  <button class="button" :id="label" @click="buttonClick()" :style="getStyle()" :class="buttonClass" :disabled="isDisabled ? isDisabled : false">
    <img v-if="img === 'require'" :src="getImgUrl(ImgSrc)" width="16px" height="16px" class="mr-2" />
    {{ label }}
  </button>
</template>

<script>
export default {
  props: ["label", "img", "buttonStyle", "ImgSrc", "buttonClass", "isDisabled"],
  methods: {
    buttonClick() {
      this.$emit("buttonClicked");
    },
    getStyle() {
      switch (this.buttonStyle) {
        case "activeButtonClass":
          return "font-size: 12px;width:95px; height: 30px; color: white; background-color: #1579ab;";
        case "inactiveButtonClass":
          return "font-size: 12px;width:95px; height: 30px; background-color: white; color: #1579ab;border: solid 1px #1579ab;";
        case "Add_Selected":
          return "background-color:#eeaa37; border:1px solid #eeaa37; border-radius: 4px; color:#ffff;width:120px;";
        case "login-button":
          return "background: #fff; text-transform: capitalize; border:transparent;height:2.5rem; min-width: 8rem; border-radius: 5px;  color:#17a2b8;box-shadow: 5px 8px 9px 0px rgba(64, 64, 64, 1); width: 8rem; font-size: 15px;";
        case "auth-button-style":
          return "max-width: 10.5rem; padding: .6rem; margin-top:2.5%;";
        case "request-demo-style":
          return "max-width: 10rem; padding: 0.4rem;";
        case "generate-btn":
          return "background:#2185D0;box-shadow: 0px 1px 10px #40404029;border-radius: 5px;color:#FFFFFF;";
        case "generate-clear":
          return "color:#2185D0;";
        default:
          return "";
      }
    },

    getImgUrl(imgName) {
      return require("../assets/" + imgName);
    },

  }
};
</script>

<style scoped>
.button {
  border-radius: 0px;
  width: 100%;
  font-weight: 500;
}
.button:disabled{
  opacity: .8;
}
.button:hover {
  background-position: right left;
}
.button:focus {
  outline: none;
}
.button:active {
  background: rgb(247, 245, 245);
}


/* New button style */
.auth-button{
  background: #029FE1;
  border:1px solid #005D84;
  font-size: 14px;
  color:#FFFFFF;
  font-family: "proxima-nova", sans-serif;
  border-radius: 5px;
}
.auth-button:focus{
  background: #2ab6f1;
}
.request-demo{
  background: #FFFFFF;
  border-radius: 5px;
  color:#01B3FE;
  border: 0px;
}
.request-demo2{
  background: #FFFFFF;
  border-radius: 5px;
  color:#01B3FE;
  border: 1px solid #0475A1;
  height: 30px;
  font-size: 11px;
}
.cancel-button{
  background: #FFFFFF;
  border-radius: 5px;
  color:#01B3FE;
  border: 1px solid #0475A1;
  height: 40px;
}
.profile-button{
  height: 35px;
  font-size: 12px;
  padding: 0;
}
.logout-btn{
  max-width: none !important;
}
.shipment-buttons{
  color:#2E8CD3;
  font-size: 14px;
  background: #FFFFFF;
  box-shadow: 0px 1px 10px #40404029;
  border: 1px solid #2184CE;
  border-radius: 5px;
  padding: 3px 10px !important;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.generate-btn{
  padding:.5rem 1rem;
  max-width: 10rem;
}
.role-btn{
  height: 30px;
  padding: 0rem;
  max-width: 8rem;
  font-size: 12px;
}
.cancel-btn{
  max-width: 8rem !important ;
}
@media screen and (min-width: 1000px) and (max-width: 1190px){
    
}
@media screen and (min-width: 760px) and (max-width: 1000px){
    .request-demo2{
      background: #FFFFFF;
      border-radius: 5px;
      color:#01B3FE;
      border: 1px solid #0475A1;
      height: 25px;
      font-size: 10px;
    }
}
@media screen and (min-width: 320px) and (max-width: 760px){
    .auth-button{
      height: 35px;
      font-size: 14px;
      padding: 0rem !important;
    }
}
</style>
