import Vue from "vue";
import VueRouter from "vue-router";
import { isUserAuth } from "../Service/LoginService";

Vue.use(VueRouter);

// Routes Protect

const path  = ['/','/login','pre-register'];
const routeProtect = (to, from, next) => {
        isUserAuth().then(res=>{
          console.log(res.length === 0, path.indexOf(to.path),to.path);
            if(res.length > 0 && path.indexOf(to.path) != -1){
              console.log(res," from first")
              next('/dashboard')
            }else if(res.length === 0 && path.indexOf(to.path) === -1){
              console.log(res," from second")
              next('/');
            }
            else{
              console.log(res," from last")
              next()
            }
        }).catch(e=>{
          console.log(e);
        })
        next()
      }

export default new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/syndication",
      name: "Syndication",
      component: () => import("../components/Tabs/SyndicationTab.vue"),
      // meta: {
      //   layout: 'sidebar',
      // }
    },
    {
      path: "/dashboard",
      name: "Dashboard",
      component: () => import("../pages/Dashboard.vue"),
      meta: {
        layout: 'sidebar',
      },
      beforeEnter: routeProtect
    },
    {
      path: "/order", 
      name: "Orders",
      component: () => import("../pages/Orders.vue"),
      meta: {
        layout: 'sidebar'
      },
       beforeEnter: routeProtect
    },
    {
      path: "/shipment",
      name: "Shipments",
      component: () => import("../pages/Shipments.vue"),
      meta: {
        layout: 'sidebar'
      },
       beforeEnter: routeProtect
    },

    {
      path: "/Easyship",
      name: "Easyship",
      props:true,
      component: () => import("../components/Shipments/UploadShipment.vue"),
      meta: {
        layout: 'sidebar',
        breadcrumb: {
          parent: 'Shipments',
          label: 'Shipments'
        }
      },
      beforeEnter: routeProtect
    },
    {
      path: "/Selfship",
      name: "Selfship",
      // props:true,
      component: () => import("../components/Shipments/UploadSelfShip"),
      meta: {
        layout: 'sidebar',
      },
      beforeEnter: routeProtect
    },
    {
      path: "/confirmShipment",
      name: "ConfirmShipment",
      // props:true,
      component: () => import("../components/Shipments/UploadShipmentConfirmation.vue"),
      meta: {
        layout: 'sidebar',
      },
      beforeEnter: routeProtect
    },

    {
      path: "/inventory",
      name: "Inventory",
      component: () => import("../pages/Inventory.vue"),
      meta: {
        layout: 'sidebar'
      },
      children: [
        {
          path: "/stockReset",
          component: () => import("../components/Inventories/StockReset.vue"),
        },
        {
          path: "/limitStockReset",
          component: () => import("../components/Inventories/LimitStockReset.vue"),
        }
      ],
      beforeEnter: routeProtect
    },
    {
      path: "/channel",
      name: "Channels",
      component: () => import("../pages/Channels.vue"),
      meta: {
        layout: 'sidebar'
      },
      beforeEnter: routeProtect
    },
    {
      path: "/warehouse",
      name: "Warehouses",
      component: () => import("../pages/Warehouses.vue"),
      meta: {
        layout: 'sidebar'
      },
      beforeEnter: routeProtect
    },
    {
      path: "/remittance",
      name: "Remitance",
      component: () => import("../pages/Remitance.vue"),
      meta: {
        layout: 'sidebar'
      },
      beforeEnter: routeProtect
    },
    {
      path: "/report",
      name: "Reports",
      component: () => import("../pages/Reports.vue"),
      meta: {
        layout: 'sidebar'
      },
      beforeEnter: routeProtect
    },
    {
      path: "/gst",
      name: "Gst",
      component: () => import("../pages/Gst.vue"),
      meta: {
        layout: 'sidebar'
      },
      beforeEnter: routeProtect
    },
    // {
    //   path:"/settings",
    //   name:"Settings",
    //   component: () => import("../pages/Settings.vue"),
    //   meta:{
    //     layout:'sidebar'
    //   }
    // },
    {
      path: "/user",
      name: "User",
      component: () => import("../pages/User.vue"),
      meta: {
        layout: 'sidebar'
      },
      beforeEnter: routeProtect
    },
    {
      path: "/AddUser",
      name: "Add User",
      component: () => import("../components/User/AddUser.vue"),
      meta: {
        layout: 'sidebar',
        breadcrumb: {
          parent: 'User',
          label: 'User'
        }
      },
      beforeEnter: routeProtect
    },
    {
      path: "/EditUser",
      name: "Edit User",
      props: true,
      component: () => import("../components/User/EditUser.vue"),
      meta: {
        layout: 'sidebar',
        breadcrumb: {
          parent: 'User',
          label: 'User'
        }
      },
      beforeEnter: routeProtect
    },
    // {
    //   path:"/warehouse",
    //   name:"warehouse",
    //   component:()=> import("../components/Warehouses/EditWarehouse.vue"),
    //   meta:{
    //     layout: 'sidebar'
    //   },
    // },
    {
      path: "/ViewUser",
      name: "ViewUser",
      component: () => import("../components/User/ViewUser.vue"),
      meta: {
        layout: 'sidebar'
      },
      beforeEnter: routeProtect
    },
    {
      path: "/Roles",
      name: "Roles",
      component: () => import("../components/User/Roles.vue"),
      meta: {
        layout: 'sidebar'
      },
      beforeEnter: routeProtect
    },
    {
      path: "/channel/:channelId",
      name: "Channel",
      props:true,
      component: () => import("../components/Channels/Channel.vue"),
      meta: {
        layout: 'sidebar',
        // breadcrumb: {
        //   parent: 'ViewChannel',
        //   label: 'Channels'
        // }
      },
      beforeEnter: routeProtect
    },
    {
      path: "/ViewChannel",
      name: "ViewChannel",
      component: () => import("../components/Channels/ViewChannel.vue"),
      meta: {
        layout: 'sidebar'
      },
      beforeEnter: routeProtect
    },
    {

      path: "/ChannelType",
      name: "Channel Type",
      component: () => import("../components/Channels/ChannelType.vue"),
      meta: {
        layout: 'sidebar',
        breadcrumb: {
          parent: 'Channels',
          label: 'Channels'
        }
      },
      beforeEnter: routeProtect
    },
    // {

    //   path: "/EditChannel/:channelId",
    //   name: "Edit Channel",
    //   component: () => import("../components/Channels/EditChannel.vue"),
    //   meta: {
    //     layout: 'sidebar',
    //     breadcrumb: {
    //       parent: 'Channels',
    //       label: 'Channels'
    //     }
    //   }
    // },
    {

      path: "/warehouse/:warehouseId",
      name: "Add Warehouse",
      component: () => import("../components/Warehouses/Warehouse.vue"),
      meta: {
        layout: 'sidebar',
        // breadcrumb: {
        //   parent: 'Warehouses',
        //   label: 'Warehouses'
        // }
      },
      beforeEnter: routeProtect
    },
    // {

    //   path:"/ViewWarehouse",
    //   name:"ViewWarehouse",
    //   component:()=> import("../components/Warehouses/ViewWarehouse.vue"),
    //   meta: {
    //     layout: 'sidebar'
    //   }
    // },


    // {
    //   path: "/",
    //   name: "Authentication",
    //   component: () => import("../pages/WelcomePage.vue")
    // },
    {
      path: "/oldLanding",
      name: "/",
      component: () => import("../pages/FirstPage.vue")
    },
    {
      path: "/register",
      name: "/Register",
      component: () => import("../components/WelcomePage/Register.vue")
    },

    {
      path: "/",
      name: "/Landing",
      component: () => import("../pages/Landing.vue")
    },
    {
      path: "/oldLogin",
      name: "/Login",
      component: () => import("../components/WelcomePage/Login.vue")
    },
    // {
    //   path: "/firstpage",
    //   name: "/FirstPage",
    //   component: () => import("../pages/FirstPage.vue")
    // },
    
    {
      path: "/shipment-details",
      name: "Shipment Details",
      component: () => import("../components/Shipments/ShipmentDetails.vue"),
      meta: {
        layout: 'sidebar',
        breadcrumb: {
          parent: 'Shipments',
          label: 'Shipments'
        }
      },
      beforeEnter: routeProtect
    },
    {
      path: "/profile",
      name: "Profile",
      component: () => import("../pages/Profile.vue"),
      meta: {
        layout: 'sidebar',
        // breadcrumb: {
        //   parent: 'Shipments',
        //   label: 'Shipments'
        // }
      },
      beforeEnter: routeProtect 
    },
    {
      path: "/changePassword",
      name: "ChangePassword",
      component: () => import("../pages/ChangePassword.vue"),
      beforeEnter: routeProtect
    },
    {
      path: "/pre-register",
      name: "Pre Register",
      // component: () => import("../pages/PreRegister.vue")
      component: () => import("../pages/RequestDemo.vue")
    },
    {
      path: "/pre-register-verify",
      name: "Pre Register Verify",
      component: () => import("../components/pre-register/VerifyFlow.vue")
    },
    {
      path: "/PlanInfo",
      name: "PlanInfo",
      component: () => import("../pages/Plan.vue"),
    },
    {
      path: "/account/reset/finish",
      name: "account/reset/finish",
      component: () => import("../components/WelcomePage/ResetPassword.vue"),
    },
    {
      path: "/resetPassword",
      name: "resetPassword",
      component: () => import("../components/WelcomePage/EnterEmail.vue"),
    },
    {
      path: "/forgotPassword",
      name: "forgotPassword",
      component: () => import("../pages/ForgotPassword.vue"),
    },
    {
      path: "/login",
      name: "Login",
      component: () => import("../pages/LoginPage.vue"),
    },
    {
      path: "/contact-us",
      name: "ContactUs",
      component: () => import("../pages/ContactUs.vue"),
    },
    {
      path: "/customers",
      name: "Customer",
      component: () => import("../pages/Customer.vue"),
      meta: {
        layout: 'sidebar'
      },
      beforeEnter: routeProtect
    },
  ]
});