import { Apihelper } from "./API/apiHelper.js";
const apiHelper = new Apihelper();
const userBaseUrl = process.env.VUE_APP_USER_SERVER_URL;
const statusBaseUrl = process.env.VUE_APP_CHANNEL_SERVER_URL;

// export const getUserDetailsByFilter = ( name, status, role ) => {
export const getUserDetailsByFilter = ( name ) => {
    let uri = `${userBaseUrl}/v1/users?`;
    if (name) {
        uri = uri.concat('&user-name=', name);
    }
    // if (status) {
    //     uri = uri.concat('&user-status=', status);
    // }
    // if (role) {
    //     uri = uri.concat('&user-roles=', role);
    // }
    return apiHelper.get(uri);
}

export const getUserStatus = () => {
    const uri = `${statusBaseUrl}/api/v1/companies/channels/channel-statuses`;
    return apiHelper.get(uri);
}

export const getUserRoles = () => {
    const uri = `${userBaseUrl}/v1/roles`;
    return apiHelper.get(uri);
}

// submit add or change roles
export const addChangeRole = (data) => {
    const uri = `${userBaseUrl}/v1/roles`;
    return apiHelper.post(uri, data);
}
export const updateChangeRole = (data) => {
    const uri = `${userBaseUrl}/v1/roles`;
    return apiHelper.put(uri, data);
}

export const postUserDetails = (data) => {
    const uri = `${userBaseUrl}/v1/users`;
    return apiHelper.post(uri, data);
}

// delete user
export const deleteUserDetail = (id) => {
    const uri = `${userBaseUrl}/v1/users/${id}`;
    return apiHelper.remove(uri);
}

// fetch individual user details for edit

export const userDetailsById = ( userId ) => {
    const uri = `${userBaseUrl}/v1/users/${userId}`;
    return apiHelper.get(uri);
}

// for user details update

export const updateUserDetail = (data)=>{
    const uri = `${userBaseUrl}/v1/users`;
    return apiHelper.put(uri, data);
};

// delete Roles
export const deleteRoles = (id) => {
    const uri = `${userBaseUrl}/v1/roles/${id}`;
    return apiHelper.remove(uri);
}

// list of permissions
export const getAllPermissions = () => {
    const uri = `${userBaseUrl}/v1/permissions`;
    return apiHelper.get(uri)
}

// update roles with permissions
export const updatePermissions = (data)=>{
    const uri = `${userBaseUrl}/v1/roles`;
    return apiHelper.put(uri, data);
};

// call for request demo
export const requestDemoCall = (data, recaptcha) => {
    const uri = `${userBaseUrl}/v1/requestdemo?recaptcha=${recaptcha}`;
    return apiHelper.post(uri, data);
}

// get company details
export const getCompanyDetails = () => {
    const uri = `${userBaseUrl}/v1/companies/${sessionStorage.getItem("companyID")}`;
    return apiHelper.get(uri);
}

// update company details
export const updateCompanyDetails = (data) => {
    const uri = `${userBaseUrl}/v1/companies`;
    return apiHelper.put(uri,data);
}

// update company details
export const getUserDetails = (id) => {
    const uri = `${userBaseUrl}/v1/users/email/${id}`;
    return apiHelper.get(uri);
}

// get company logo
export const getCompanyLogo = () => {
    const uri = `${userBaseUrl}/v1/company/logo`;
    return apiHelper.get(uri);
}

// get user profile logo
export const getProfileLogo = () => {
    const uri = `${userBaseUrl}/v1/user/profile/picture`;
    return apiHelper.get(uri);
}

// upload company logo 
export const uploadCompanyLogo = (formData) => {
    const uri = `${userBaseUrl}/v1/company/logo`;
    return apiHelper.post(uri, formData);
}

// upload user image 
export const uplopadUserImage = (formData) => {
    const uri = `${userBaseUrl}/v1/user/profile/picture`;
    return apiHelper.post(uri, formData);
}