<template>
  <div id="app">
    <!-- for user profile -->
    <!-- <div class="user-profile">
      <div class="pl-4">LOGO</div>
      <div class="pr-5">Profile</div>
    </div> -->
    
    <component :is="layout">
      <div class="sub-content-tab" v-if="layout != 'no-sidebar-layout'"></div>
      <router-view  :class="layout === 'no-sidebar-layout'?'':'route-position'" />
    </component>
  </div>
</template>

<script>
const default_layout = "no-sidebar";

export default {
  computed: {
    layout() {
      return (this.$route.meta.layout || default_layout) + "-layout";
    },
  },
};
</script>
<style>
.user-profile{
  height: 55px;
  position: sticky;
  top: 0;
  z-index: 2222;
  background:#fff;
  display: flex;
  justify-content: space-between;
  align-items: center;

}
/* @import url("https://fonts.googleapis.com/css?family=Proxima+Nova"); */
/* @import url("https://use.typekit.net/crl6igd.css"); */
@font-face {
  font-family: "proxima-nova", sans-serif;
  src: url('./assets/fonts/ProximaNova-Regular.otf');
}
@font-face {
  font-family: "bebas-kai";
  src: url('./assets/fonts/BebasKai.otf');
}
@font-face {
  font-family: "roboto-bold";
  src: url('./assets/fonts/Roboto-Bold.ttf');
}
@font-face {
  font-family: "roboto-regular";
  src: url('./assets/fonts/Roboto-Regular.ttf');
}
@font-face {
    font-family: Quasimoda;
    src: url('./assets/fonts/quasimoda-light.otf');
}

body,
.breadcrumb {
  font-family: "proxima-nova", sans-serif !important;
  background: #efefef !important;
}
.vl {
  border-left: 2px solid #637381;
  height: 18px;
  margin-top: 15px;
}
.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none;
}
.no-margin {
  margin: 0 !important;
}
/* Tabs CSS start */

.report-tabs .nav-tabs {
  padding-left: 15px;
  /* background: white; */
}
.nav-tabs .nav-item {
  padding-right: 20px;
}
.report-tabs .nav-tabs .nav-item a {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 15px !important;
  font-weight: 500;
  color: darkgray;
  padding-bottom: 6px !important;
  padding-top: 20px !important;
  white-space: nowrap;
}
.report-tabs .nav-tabs .nav-link.active,
.nav-tabs .nav-link:hover {
  border-bottom: 3px solid #2185d0 !important;
  border-color: transparent;
  color: #2185d0;
  background: transparent;
}
.report-tabs .nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: transparent;
}
/* Tabs CSS end */

.breadcrumb {
  height: 20px;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #637381;
}
@media (min-width: 1280px) and (max-width: 1365px) {
  .container {
    width: 720px !important;
  }
}
@media (max-width: 1366px) {
  /* .container {
    width: 768px !important;
  } */
}
@media (min-width: 1367px) and (max-width: 1920px) {
  .container {
    max-width: 1366px !important;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1366px !important;
  }
}
.page-item.active .page-link {
  z-index: 3;
  color: #007bff !important;
  border-radius: 2px;
  border: solid 1px #007bff !important;
  background-color: #ffffff !important;
  font-size: 12px;
}
.page-link {
  z-index: 3;
  color: #767676 !important;
  min-width: 35px;
  height: 26px;
  opacity: 0.7;
  border-radius: 2px;
  border: solid 1px #c6cbd4 !important;
  background-color: #ffffff !important;
  font-size: 12px;
  line-height: 0.6 !important;
  margin-left: 4px !important;
}
.nav-tabs .nav-link {
  padding-bottom: 13px;
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border: 1px solid transparent;
}
.nav-tabs .nav-link.active {
  border: 1px solid transparent;
  color: #212b36;
  border-bottom: 2px solid #5c6ac4;
}
.breadcrumb {
  padding-left: 0;
  margin-top: -35px;
  background: transparent !important;
}
.breadcrumb-item:first-child::before {
  content: "‹";
  font-size: 25px;
  margin-right: 2px;
  line-height: 0;
  position: relative;
  top: 2px;
  height: 10px;
}
.breadcrumb-item a {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: left;
  color: #5fa6e7;
}
.breadcrumb-item.active {
  color: rgb(173, 173, 173);
}
.breadcrumb-item.active span {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #1f1f1f;
}
nav ul li {
  position: relative;
}
nav ul li a {
  padding: 4px 0 6px;
  font-size: 1.1em;
  display: block;
  color: #fff;
  font-size: 14px;
  -webkit-transition: none;
  transition: none;
  /* border-left: 3px solid #ffffff00; */
  border: 1px solid #ffffff00;
  text-align: center;
  height: 58px;
}
/* nav ul li a:hover {
  font-size: 1.1em;
  display: block;
  color: #fff;
  font-size: 14px;
  -webkit-transition: none;
  text-align: center;
  transition: none;
  background-color: #ffffff00 !important;
} */
nav ul li a:link {
  color: white;
  text-decoration: none;
  font-size: 14px;
}
nav ul li a:visited {
  color: white;
  text-decoration: none;
  font-size: 14px;
  text-align: center;
}
nav ul li a:active {
  color: white;
  text-decoration: none;
  font-size: 14px;
}
nav ul.components {
  padding: 0 0 30px;
  border-bottom: 0 !important;
  margin-top: 83px;
}
/* nav ul.components li:hover {
  background: #ffffff10;
  border-radius: 9px;
  height: 61px;
} */
nav ul li a {
  border-left: 0px solid #ffffff00;
  display: flex;
  opacity: 0.8;
  flex-direction: column;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
nav ul li a:hover{
  background: #ffffff10;
  /* border-radius: 9px; */
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  text-decoration: none;
}
nav ul li .active:hover{
  background: #EFEFEF;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
nav ul li .active {
  background: #EFEFEF;
  opacity: 1;
  margin-right: 0 !important;
}
nav ul li .active:before, nav ul li .active:after {
  content: "";
  width:20px;
  height:20px;
  position: absolute;
  right:0px;
}
nav ul li .active:before {
  top:-20px;
  border-radius:60% 0;
  box-shadow: 4px 4px 2px 2px #eeeeee;
}
nav ul li .active:after {
  bottom:-20px;
  border-radius: 0 60%;
  box-shadow: 4px -4px 2px 2px #eeeeee;
}
nav ul li .active p{
  display: block !important;
  color: #222838;
}
nav ul li .active .activeIcon{
  display: block !important;
}
nav ul li .active .iconName{
  display: none !important;
}
/* // checkbox color */
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #000 !important;
  background-color: #000 !important;
}
.custom-control-label::before {
  border: #000 solid 1px !important;
}
.pac-container {
  z-index: 100000 !important;
}
/* label class */
.label-class {
  opacity: 0.4;
  font-family: "proxima-nova", sans-serif !important;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}
.page-title {
  text-align: left;
  font-family: "proxima-nova", sans-serif !important;
  font-size: 18px;
  letter-spacing: 0;
  color: #191d24;
  opacity: 1;
}
.page-title-icon {
  width: 24px;
}
body {
  background-color: #007bff;
}
.mx-datepicker-range {
  width: 100% !important;
}
.mx-input {
  height: 37px !important;
  opacity: 0.6;
  color: #555;
  box-shadow: 0px 1px 10px #40404029;
}
button:focus {
  outline: none !important;
}
.tree-label {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #767676;
}
.shipped-status-class {
  height: 30px;
  width: 100px;
  border-radius: 5px;
  background-color: #3ac9749e;
  border-color: white;
  font-family: "proxima-nova", sans-serif !important;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.17;
  color: #141a1f;
}
.delivered-status-class {
  height: 30px;
  width: 100px;
  border-radius: 2px;
  /* background-color: #BDC3C7  ; */
  border-color: white;
  font-family: "proxima-nova", sans-serif !important;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.17;
  color: #141a1f;
}
.pickedUp-status-class {
  height: 30px;
  width: 100px;
  border-radius: 2px;
  /* background-color: #FAD7A0  ; */
  border-color: white;
  font-family: "proxima-nova", sans-serif !important;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.17;
  color: #141a1f;
}
.unshipped-status-class {
  height: 30px;
  width: 100px;
  border-radius: 2px;
  background-color: #1079db7d;
  border-color: white;
  font-family: "proxima-nova", sans-serif !important;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.17;
  color: #141a1f;
}
.canceled-status-class {
  height: 30px;
  width: 100px;
  border-radius: 2px;
  background-color: #efc6b9;
  border-color: white;
  font-family: "proxima-nova", sans-serif !important;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.17;
  color: #141a1f;
}
.pending-status-class {
  height: 30px;
  width: 100px;
  border-radius: 2px;
  background-color: #80008085;
  border-color: white;
  font-family: "proxima-nova", sans-serif !important;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.18;
  color: #141a1f;
}
.summary_refresh_text {
  font-family: "proxima-nova", sans-serif !important;
  color: #bfbfbf;
  line-height: 11px;
  font-size: 12px;
  margin-right: 10px;
}
 /* Pre Register css */
label {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #363636;
}
.form-group {
  margin-top: 10px !important;
}
.form-control {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21 !important;
  letter-spacing: normal;
  text-align: left;
  height: 45px !important;
  color: #495057 !important;
  /* opacity: 0.6; */
}
.head-label {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: center;
  color: rgb(69 117 193);
}
.sub-header-label {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: #767676;
}
.label-star {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #dc3545;
}
.secondry-head-label {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: normal;
  text-align: center;
  color: #363636;
}
.button-danger {
  height: 45px !important;
  border-radius: 6px !important;
  background-color: rgb(69 117 193) !important;
  width: 100% !important;
  border-color: rgb(69 117 193) !important;
}
.button-next {
  height: 45px !important;
  border-radius: 6px !important;
  background-color: #dc3545 !important;
  width: 200px !important;
  border-color: #dc3545 !important;
  margin-left: auto;
}
.button-pay {
  height: 35px !important;
  border-radius: 6px !important;
  background-color: #dc3545 !important;
  width: 100px !important;
  border-color: #dc3545 !important;
  margin-left: auto !important;
}
.login-button,
.contactus-button {
  font-family: "proxima-nova", sans-serif !important;
  min-width: 138px !important;
  min-height: 35px !important;
  border-radius: 4px !important;
  border: solid 1px #f55f34 !important;
  background-color: #dc3545 !important;
  font-size: 14px !important;
  font-weight: normal !important;
  text-align: center !important;
  color: #ffffff !important;
}
.contactus-button {
  background-color: #ffffff !important;
  color: #f55f34 !important;
}
@media only screen and (max-width: 768px){
  .user-profile{
    display: none !important;
   
  }
}
@media (max-width: 768px) {
  .login-button {
    width: calc(50% - 0.5rem);
  }
  .contactus-button {
    width: calc(50% - 0.5rem);
  }
  nav ul li .active {
  background: #1b1c1d;
  /* opacity: 1;
  margin-right: 0 !important;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px; */
  /* padding: 11px; */
  }
  nav ul li .active .activeIcon{
    display: none !important;
  }
  nav ul li .active .iconName{
    display: block !important;
  }
  nav ul li .active p{
    color: #fff;
    padding: 15px 0;
  }
  .report-tabs .nav-tabs{
    padding-left: 0;
    background: transparent;
  }
  .nav-tabs{
    border-bottom: none !important;
  }
  .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
    background: transparent !important;
  }
  .report-tabs .nav{
    flex-wrap: unset !important;
    overflow-x: auto;
    background: #efefef;
    position: fixed;
    /* top: 5.7%; */
    top: 51px;
    z-index: 1111;
    width: 100%;
    padding-bottom: 15px;
  }
  ::-webkit-scrollbar {
    display:none
  }
  .nav-tabs .nav-item{
    padding-right: 0;
  }
  .report-tabs .nav-tabs .nav-item a{
    font-size: 13px !important;
  }
}
@media (max-width: 480px){
  .report-tabs .nav-tabs .nav-item a{
    /* margin-left: 100px; */
    font-size: 13px !important;
    padding: .5rem 6px;
  }
  .report-tabs .nav{
    justify-content: flex-start !important;
  }
  
}
@media (min-width: 992px) {
  .container-sm,
  .container-md,
  .container-lg {
    max-width: 700px !important;
  }
}
.code-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 992px) {
  .code-container {
    max-width: 264px !important;
  }
}
.vue-tel-input:focus-within {
  box-shadow: none !important;
  border: none !important;
}

.vue-tel-input {
  border: none !important;
  height: 45px;
}
.vue-tel-input > input {
  font-family: "proxima-nova", sans-serif !important;
  border: 1px solid #ced4da !important;
  margin-left: 10px;
  border-radius: 2px;
  font-family: "proxima-nova", sans-serif !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21 !important;
  letter-spacing: normal;
  text-align: left;
  height: 45px !important;
  color: #495057 !important;
}
.vue-tel-input > input::placeholder,
.form-control::placeholder {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.21 !important;
  letter-spacing: normal !important;
  text-align: left !important;
  color: #bcbcbc !important;
}
.vti__dropdown {
  border: 1px solid #ced4da !important;
  width: 150px;
  border-radius: 2px;
}
.vti__selection .vti__country-code {
  margin-left: 15px;
}
.vti__dropdown-arrow {
  margin-left: 15px;
}
.vue-tel-input > input:focus,
.vti__dropdown:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  /* box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); */
}
.step-header.active .step-header-content {
  background-color: #dc3545 !important;
}
.foot {
  display: none !important;
}
.step-header-line {
  width: 30px !important;
}
.step-header-content {
  width: 35px !important;
  height: 35px !important;
}
.title {
  width: 30px !important;
}
@media (min-width: 700px) {
  .top {
    margin-left: auto !important;
    width: 35% !important;
  }
}
.heading-regs {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #363636;
}
.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #ff7d51 !important;
  background-color: #ff7d51 !important;
}
.custom-switch .custom-control-label::after {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.16) !important;
  border: solid 3px #ff7d51 !important;
  background-color: #fdfdfe !important;
}
legend {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 14px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21 !important;
  letter-spacing: normal;
  text-align: left;
  color: #363636 !important;
}
.hidden_header {
  display: none !important;
}
.verified-button {
  height: 40px !important;
  color: #ff7d51 !important;
  background-color: rgb(247, 247, 247) !important;
  border-color: transparent !important;
  outline: none !important;
  box-shadow: none !important;
}
@media (min-width: 992px) {
  .card-class {
    max-width: 1200px !important;
  }
}
.modal-header {
  border-bottom: none !important;
}
.thClass {
  text-align: center !important;
}
/* style for pages */
.page-heading {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 19px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.37;
  letter-spacing: normal;
  text-align: left;
  color: #1b1c1d;
}
.icon-button {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 14px !important;
  font-weight: 600;
  text-align: center;
  color: #f55f34 !important;
  width: 200px !important;
  height: 40px !important;
  color: #ff7d51 !important;
  background-color: #ffffff !important;
  border-color: #f55f34 !important;
  outline: none !important;
  box-shadow: none !important;
}
.sub-icon-button {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 14px !important;
  font-weight: 600;
  text-align: center;
  color: #f55f34 !important;
  color: #ff7d51 !important;
  background-color: #ffffff !important;
  border-color: #f55f34 !important;
  outline: none !important;
  box-shadow: none !important;
}
.full-button {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 14px !important;
  font-weight: 600;
  text-align: center;
  color: #f55f34 !important;
  width: 200px !important;
  height: 40px !important;
  color: #ffffff !important;
  background-color: #f55f34 !important;
  border-color: #f55f34 !important;
  outline: none !important;
  box-shadow: none !important;
}
.customer-heading {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 20px;
  font-weight: 600;
  color: #dc3545;
  width: 100%;
}
.sub-customer-heading {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 14px;
  font-weight: normal;
  color: #767676;
  width: 100%;
}
.pointer {
  cursor: pointer;
}
.page-title {
  border: 0 !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
}
/* temporarily hiding recaptcha badge */
.grecaptcha-badge {
  display: none;
}
/*  */
.tab-content {
  /* margin: 0rem 60px; */
  margin: 0rem 47px 40px 60px;
}
.whithout-tab-content{
  /* margin: 0 60px; */
  margin: 0rem 47px 40px 60px;
}
.sub-content-tab{
  height: 68px;
}

.route-position{
  /* margin-top: 4.7%; 
  margin-left: 6%; */
  margin-left: 5%;
}
@media screen and (max-width:800px) {
  
  .route-position{
    margin-left: 0%;
  }
  .tab-content{
    margin: 0rem 10px;
  }
  .whithout-tab-content{
    margin: 0 10px;
  }
  
  .sub-content-tab{
    height: 50px;
  }
  .nav-tabs .nav-item{
    padding: 0px;
  }
}
.card{
  box-shadow: 0px 1px 10px #40404029;
}
.alert-danger{
  color:#ff0a0d !important;
  background: none !important;
  border: none !important;
  padding: 0 !important;
  font-size: 12px;
}
@media screen and (max-width:330px) {
  .table-pagination{
    display: contents !important;
  }
  .show-class{
    margin-left: auto !important;
  }
}
.close-filter-icon{
  cursor: pointer;
  position: absolute;
  right: 10px;
  z-index: 2;
  top:0;
}
.pointer {
  cursor: pointer;
}
</style>
