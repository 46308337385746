import axios from "axios";
const BASE_HEADERS = "";
const validStatuses = [200, 201, 202, 400, 204, 403];
const VUE_APP_COUNTRIRES_API = 'https://restcountries.eu/rest/v2/all';

export class Apihelper {
    get(uri) {
        return axios
            .get(uri, {
                headers: this.getHeaders(),
                withCredentials: false,
            })
            .then((res) => {
                var data = res;
                return data;
            })
            .then(this.checkResponse)
            .catch(this.handleError);
    }

    post(uri, data) {
        return axios
            .post(uri, data, {
                headers: this.getHeaders(),
                withCredentials: false,
            })
            .then((res) => {
                return res;
            })
            .then(this.checkResponse)
            .catch(this.handleError);
    }

    put(uri, data) {
        return axios
            .put(uri, data, {
                headers: this.getHeaders(),
                withCredentials: false,
            })
            .then((res) => {
                var data = res;
                return data;
            })
            .then(this.checkResponse)
            .catch(this.handleError);
    }

    downloadFile(uri) {
        return axios({
            headers: this.getHeaders(),
            url: uri,
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {
            if( response.request.getResponseHeader('Content-Disposition') != null ){
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');
                fileLink.href = fileURL;
                fileLink.setAttribute('download', response.request.getResponseHeader('Content-Disposition').split("filename=")[1]); //or any other extension
                document.body.appendChild(fileLink);
                fileLink.click();
            }
        })
            .catch(this.handleError);
    }
// for download listing status bulk template in inventory start
    downloadListingFiles(uri, data) {
        return axios({
            headers: this.getHeaders(),
            url: uri,
            method: 'POST',
            responseType: 'blob',
            data:data
        }).then((response) => {
            if( response.request.getResponseHeader('Content-Disposition') != null ){
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');
                fileLink.href = fileURL;
                fileLink.setAttribute('download', response.request.getResponseHeader('Content-Disposition').split("filename=")[1]); //or any other extension
                document.body.appendChild(fileLink);
                fileLink.click();
            }
        })
            .catch(this.handleError);
    }

    // end
    
    downloadRemittanceFile(uri) {
        return axios({
            headers: this.getHeaders(),
            url: uri,
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', response.request.getResponseHeader('Content-Disposition').split("filename=")[1]); //or any other extension
            document.body.appendChild(fileLink);
            fileLink.click();
        })
            .catch(this.handleError);
    }
    // download View mtr in GST module
    downloadViewMtrFile(uri) {
        return axios({
            headers: this.getHeaders(),
            url: uri,
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {
            console.log("view mtr download", response)
            // if( response.request.getResponseHeader('Content-Disposition') != null ){
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');
                fileLink.href = fileURL;
                fileLink.setAttribute('download', 'cancelled_order_invoice.xlsx'); //or any other extension
                document.body.appendChild(fileLink);
                fileLink.click();
            // }
        })
            .catch(this.handleError);
    }
    // /////

    remove(uri) {
        return axios
            .delete(uri, {
                headers: this.getHeaders(),
                withCredentials: false,
            })
            .then(this.checkResponse)
            .catch(this.handleError);
    }
    
    getCountryList() {
        return axios
          .get(VUE_APP_COUNTRIRES_API)
          .then(this.checkResponse)
          .catch(this.handleError);
      }

    getHeaders(multipart = false) {
        let defaultHeaders = BASE_HEADERS;
        if (multipart) {
            defaultHeaders = {};
        }
        if (sessionStorage.getItem("token")) {
            defaultHeaders = {
                Authorization: "Bearer " + sessionStorage.getItem("token"),
                ...defaultHeaders,
            };
        }
        return defaultHeaders;
    }

    checkResponse(response) {
        if (validStatuses.includes(response.status)) {
            return response.data;
        }
        // If not authorized then reset authorization
        // and redirect to login page
        if (response.status === 401) {
            // localStorage.clear();
            sessionStorage.clear();
            return Promise.reject(new Error("USER_ANONYMOUS"));
        } else {
            let err = new Error(response.statusText);
            err.response = response;
            return Promise.reject(err);
        }
    }

    handleError(error) {
        if (error.response && error.response.status) {
            switch (error.response.status) {
                case 401:
                    // localStorage.clear();
                    if(window.location.pathname != "/login"){
                        window.location.replace("/login");
                        sessionStorage.clear();
                        return Promise.reject(error.response.data);
                    }else{
                        return Promise.reject(error.response.data);
                    }
                    
                case 400:
                    return Promise.reject(error.response.data);
                case 404:
                    return Promise.reject(error.response.data);
                case 403:
                    return Promise.reject(error.response.data);
                default:
                    return Promise.reject(error.response.data);
            }
        } else if (error.response) {
            let err = new Error(error.response);
            return Promise.reject(err);
        } else {
            let err = new Error(error);
            return Promise.reject(err);
        }
    }
}
