<template>
  <div>
    <!-- <br /> -->
    <div class="">
      <b-breadcrumb v-if="showBreadcrumb">
        <div v-for="link in breadcrumbItems" :key="link">
          <b-breadcrumb-item v-if="!link.active" :to="link.to" class="mr-1"
            >{{ link.text }}
          </b-breadcrumb-item>
          <span v-else class="text-to-class"> / {{ link.text }}</span>
        </div>
      </b-breadcrumb>
    </div>
  </div>
</template>
<script>
const ESCAPE_LOGOUT_HASH = "#logout";

export default {
  data() {
    return {
      breadcrumbItems: [
        { text: "", to: "" },
        { text: "", active: true },
      ],
      showBreadcrumb: false,
    };
  },
  created() {
    this.determineBreadcrumb();
  },
  methods: {
    getRouteMap() {
      if (sessionStorage.getItem("routeMap")) {
        // Code written for page refresh
        let json = JSON.parse(sessionStorage.getItem("routeMap"));
        json = json.filter((e) => e !== "[Unknown]");
        let newMap = new Map(json);
        return newMap;
      } else {
        return new Map();
      }
    },
    addRouteMap(_currentRoute) {
      let _routeMap = new Map();
      if (sessionStorage.getItem("routeMap")) {
        // Code written for page refresh
        let json = JSON.parse(sessionStorage.getItem("routeMap"));
        json = json.filter((e) => e !== "[Unknown]");
        _routeMap = new Map(json);
      }
      if (_currentRoute.hash !== ESCAPE_LOGOUT_HASH) {
        _routeMap.set(_currentRoute.name, _currentRoute);
      }
      let _routeMapArray = Array.from(_routeMap.entries());
      let _routeString = JSON.stringify(
        _routeMapArray,
        this.censor(_routeMapArray)
      );
      sessionStorage.setItem("routeMap", _routeString);
    },
    // Code used for breadcumbs issue
    censor(censor) {
      var i = 0;
      return function(key, value) {
        if (
          i !== 0 &&
          typeof censor === "object" &&
          typeof value == "object" &&
          censor == value
        )
          return "[Circular]";
        if (i >= 29)
          // seems to be a harded maximum of 30 serialized objects?
          return "[Unknown]";
        ++i; // so we know we aren't using the original object anymore
        return value;
      };
    },
    clearRouteMap() {
      sessionStorage.removeItem("routeMap");
    },
    determineBreadcrumb() {
      let currentRoute = this.$route;
      //if parent is present, it means it is sub-route
      if (
        currentRoute.meta &&
        currentRoute.meta.breadcrumb &&
        currentRoute.meta.breadcrumb.parent
      ) {
        //look for parent route in map
        let parentRouteName = currentRoute.meta.breadcrumb.parent;
        if (this.getRouteMap() && this.getRouteMap().has(parentRouteName)) {
          let label = currentRoute.meta.breadcrumb.label;
          this.showBreadcrumb = true;
          let parentRoute = this.getRouteMap().get(parentRouteName);
          this.breadcrumbItems[0].text = label ? label : parentRoute.name;
          this.breadcrumbItems[0].to = parentRoute.path;
          this.breadcrumbItems[1].text = currentRoute.name;
        }
      } else {
        //no parent route, reset routemap
        this.clearRouteMap();
        //hide breadcrumb
        this.showBreadcrumb = false;
      }
      this.addRouteMap(currentRoute);
    },
  },
  watch: {
    $route() {
      this.determineBreadcrumb();
    },
  },
};
</script>
<style scoped>
/* ::v-deep .breadcrumb{
  margin-left: -8px;
} */
.text-to-class {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color:#767676;
}
::v-deep .breadcrumb{
  margin-top: 0;
}
</style>
