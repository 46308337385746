<template>
  <div class="container-fluid wrapper">
    <NavBar :companyDetail="companyDetail" :comapnyLogo="comapnyLogo" :userDetails="userDetails" :userLogo="userLogo" :companyLogoUrl="companyLogoUrl" :profileLogoUrl="profileLogoUrl" />
    <div class="row">
      <!-- for desktop -->
      <div class="p-0 side-menu">
        <slot name="sidebar-header"></slot>
        <nav id="sidebar" ref="sidebar">
          <!-- <p class="app-logo mb-0">
            <img src="../../assets/nav/HectocommerceLogo.svg">
          </p> -->
          <ul class="tree list-unstyled" :class="'components'">
            <li
              :id="node.iconName"
              :key="i"
              v-for="(node, i) in links"
              class="nav-li text-center pl-2"
              style="cursor:pointer"
              v-b-popover.hover.right="node.label"
            >
              <template>
                <dyna-link v-if="node.hidden" :link="node"></dyna-link>
              </template>
            </li>
          </ul>
        </nav>
      </div>

      <!-- for mobile Sidebar -->
      <div class="w-100 openMenubtn" v-if="isMenuButton">
        <div class="menu-btn-style">
          <div>
            <img src="../../assets/SVG/menu.svg"  @click="openSidebar()">
          </div>
          <div class="mb-0 ml-auto mr-4">
            <img src="../../assets/nav/HectocommerceLogo.svg">
          </div>
        </div>
      </div>
      <div id="openedMenuBar" v-if="isMenuOpen">
        <div
          class="d-flex justify-content-between align-items-center pt-4 pb-2 px-4"
          style="font-size:25px; font-weight:bold"
        >
          <p class="mb-0">
            <img src="../../assets/nav/HectocommerceLogo.svg">
          </p>
          <!-- <button @click="closeSidebar()"> -->
            <div class="menu-btn-style">
              <div class="d-flex">
                <img src="../../assets/SVG/menu.svg"  @click="closeSidebar()">
              </div>
              <!-- <p class="mb-0">
                <span>LOGO</span>
              </p> -->
            </div>
          <!-- </button> -->
        </div>
        <div class="organization-wrapper">
          <!-- <div class="profile-pop-up"> -->
            <div class="user-icon profile-user-icon p-0">
              <!-- {{comapnyLogo}} -->
              <img :src="companyLogoUrl" width="40px" height="40px" style="border-radius:50%" />
            </div>
            <div class="profile-organization-name organization-name">{{companyDetail.companyName}}</div>
            <a class="user-detail-link" @click="goToProfile()">View Company / User Profile</a>
            <hr class="divider-line" />
            <div class="user-section-wrapper">
              <div class="user-icon profile-user-icon m-0 p-0">
                <!-- {{userLogo}} -->
                <img :src="profileLogoUrl" width="40px" height="40px" style="border-radius:50%" />
              </div>
              <div class="user-section">
                <p class="user-name">{{userDetails.firstName}} {{userDetails.lastName}}</p>
                <p class="user-mail">{{userDetails.email}}</p>
                <p class="user-name user-position">Company Administrator</p>
              </div>
            </div>
            <hr class="divider-line" />
            
          <!-- </div> -->
        </div>
        <slot name="sidebar-header"></slot>
        <!-- <nav class="side-navbar" id="sidebar">
          <ul class="navTab-style">
            <li
              :key="i"
              v-for="(node, i) in links"
              class="navLink"
              style="cursor:pointer"
              @click="closeSidebar"
            >
              <template>
                <dyna-link :link="node"></dyna-link>
              </template>
            </li>
          </ul>
        </nav> -->
        <div class="row p-0 m-0">
          <div
              :key="i"
              v-for="(node, i) in links"
              style="cursor:pointer"
              class="col-3 p-1"
              @click="closeSidebar"
            >
            <div class="mobile-nav-item">
              <template>
                <dyna-link :link="node"></dyna-link>
              </template>
            </div>
          </div>
        </div>
            <hr class="divider-line" />
            <div class="row p-0 m-0">
              <div class="col-12 p-0 m-0 text-center">
                <w-input-button 
                  :buttonClass="'auth-button profile-button'"
                  :buttonStyle="'auth-button-style'"
                  @buttonClicked="logoutButton()"
                  :disabled="isloginDetail"
                  :label="'Logout'"
                />
              </div>
              <div class="col-12 p-0 m-0 text-center">
                <w-input-button 
                  class="mt-3"
                  :buttonClass="'request-demo profile-button'"
                  :buttonStyle="'request-demo-style'"
                  :label="'Change Password'"
                />
              </div>
            </div>
      </div>
      <!-- end -->

      <div class="p-0 main-section">
        <div id="main" style="background: #efefef">
          <div id="content" ref="content">
            <!-- <Breadcrumb /> -->
            <slot>
              <div v-bind:key="i" v-for="i in 100">
                <h3></h3>
                <p></p>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>

    <!-- error handling modal -->
    <Modal
      :trigger="alert"
      :title="'HectoCommerce'"
      :centered="true"
      :showModalTable="false"
      :dontCloseWhenClickedOutside="true"
      :dontCloseWhenEscapePressed="true"
      :dontShowCloseIcon="true"
      :modalbuttonStyle="true"
      :alert="alert"
      :alertMsg="alertMsg"
      :alertType="alertType"
      :isConfirmAlert="isConfirmAlert"
      @eventClose="closeModal()"
      @confirmOkEvent="confirmOk"
    />

  </div>
</template>

<script>
import DynaLink from "./Dynalink.vue";
import Breadcrumb from "../Breadcrumb";
import NavBar from "../../Layouts/NavBar.vue";
import ButtonInput from "../../widgets/InputButton.vue";
import Modal from "../../widgets/ModalWidget.vue";
import { getCompanyDetails, getUserDetails, getCompanyLogo, getProfileLogo } from "../../Service/UserService.js";

export default {
  props: {
    heading: { type: String },
    links: { type: Array },
    "show-header": { type: Boolean, default: true },
    "header-links": { type: Array },
  },
  data() {
    return {
      alertType: "",
      alert: false,
      alertMsg: "",
      isConfirmAlert: false,

      isMenuOpen: false,
      isMenuButton: true,
      isLogOutPrompt: false,
      header: [],
      windowWidth: 768,
      companyDetail:{},
      comapnyLogo:"",
      userDetails:{},
      userLogo:"",
      companyLogoUrl:"",
      profileLogoUrl:"",
    };
  },
  components: {
    "dyna-link": DynaLink,
    Breadcrumb,
    NavBar,
    "w-input-button":ButtonInput,
    Modal
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.getCompanyDetail();
    this.getUserDetails();
    this.getCompanyLogo();
    this.getProfileLogo();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },

  methods: {
    // get company logo
    getCompanyLogo(){
      getCompanyLogo()
      .then((res) => {
        this.companyLogoUrl= res;
      })
      .catch((err) => {
        this.alert = true;
        this.alertMsg = err.message;
        this.alertType = "Failure";
        this.isConfirmAlert = false;
      });
    },

    // get profile logo
    getProfileLogo(){
      getProfileLogo()
      .then((res) => {
        this.profileLogoUrl= res;
      })
      .catch((err) => {
        this.alert = true;
        this.alertMsg = err.message;
        this.alertType = "Failure";
        this.isConfirmAlert = false;
      });
    },

    getCompanyDetail(){
      getCompanyDetails().then(res=>{
        this.companyDetail = res;
        if(this.companyDetail.logoUrl === null){
          let logo = this.companyDetail.companyName.split(" ");
          this.comapnyLogo = `${logo[0][0]}${logo[1] ? logo[1][0]: ''}`;
        }
      })
      .catch((err) => {
        this.alert = true;
        this.alertMsg = err.message;
        this.alertType = "Failure";
        this.isConfirmAlert = false;
      });
    },

    getUserDetails(){
      getUserDetails(sessionStorage.getItem('userId')).then(res=>{
        this.userDetails = res;
        this.userLogo = this.userDetails.firstName[0]+this.userDetails.lastName[0]
      })
      .catch((err) => {
        this.alert = true;
        this.alertMsg = err.message;
        this.alertType = "Failure";
        this.isConfirmAlert = false;
      });
    },
    handleResize() {
      if (window.innerWidth > this.windowWidth) {
        this.isMenuOpen = false;
        // this.isMenuButton = false;
      } else {
        // this.isMenuButton=true;
      }
    },

    onLogOutChange(e) {
      this.isLogOutPrompt = e;
    },
    toggleSidebar() {
      this.$refs.sidebar.classList.toggle("active");
      this.$refs.navbar.classList.toggle("active");
      this.$refs.content.classList.toggle("active");
    },
    getLoggedInUserName() {
      return localStorage.getItem("userFullName");
    },
    showApiError() {
      return this.$store.state.showApiError;
    },
    goToURL() {
      window.open(process.env.VUE_APP_HELP_SUPPORT_URL, "_blank");
    },

    // for navabar toggle
    openSidebar() {
      this.isMenuOpen = true;
      this.isMenuButton = false;
    },

    closeSidebar() {
      setTimeout(this.menuOpenclose, 300);
    },
    menuOpenclose() {
      this.isMenuOpen = false;
      this.isMenuButton = true;
    },
    logoutButton(){
      sessionStorage.clear();
      localStorage.clear();
      this.$router.push("/");
    },

    closeModal() {
      this.alert = false;
      this.isConfirmAlert = false;
      this.alertMsg = "";
      this.alertType = "";
    },
    goToProfile(){
      this.$router.push("/profile")
      setTimeout(this.menuOpenclose, 300);
    },
  },
};
</script>

<style scoped>
.side-menu {
  display: block;
  min-width: 85px;
  max-width: 85px;
  height: 100vh;
  background: #023449;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}
.main-section {
  width:100%;
}
.app-logo {
  font-weight: bold;
  background: #fff;
  color: black;
  border-bottom-right-radius: 100%;
  height: 48px;
  line-height: 48px;
  opacity: 1;
  z-index: 11111;
  box-shadow: 0px 5px 0px #ccc;
  visibility: hidden;
}

#sidebar {
  color: #fff;
  transition: all 0.3s;
  z-index: 10000;
  text-align: center;
}
.openMenubtn {
  display: none;
  /* margin-bottom: 20px; */
}
#openedMenuBar {
  height: 104vh;
  background: rgb(201,201,201);
  background: linear-gradient(180deg, rgba(201,201,201,1) 5%, rgba(255,255,255,1) 40%);
  z-index: 2222;
  position: fixed;
  width: 100vw;
}
.organization-wrapper{
  text-align: center;
  align-items: center;
}
/* side navbar style */
.side-navbar .navTab-style {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  margin: auto;
}
.side-navbar .navTab-style .navLink {
  list-style: none;
  padding: 25px;
  margin: 1%;
  background: #1b1c1d;
  border-radius: 10px;
}

.menu-btn-style {
  display: flex;
}
.menu-btn-style div {
  font-size: 35px;
  padding-left: 20px;
}
.menu-btn-style p {
  background: #1b1c1d;
  border-radius: 0% 100% 52% 52% / 0% 0% 100% 100%;
  height: 50px;
  width: 130px;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
}
.menu-btn-style p span {
  text-align: center;
  display: block;
  line-height: 50px;
  color: #fff;
  font-size: 25px;
  font-weight: bold;
}
 ::v-deep .popover-body{
  font-size: 12px;
  padding: .4rem .4rem;
}
/* Responsive layout */
@media only screen and (min-width: 768px) {
  /* .app-logo {
    margin-bottom: 25px !important;
  } */
}
@media only screen and (max-width: 800px) {
  .side-menu {
    display: none;
  }
  .main-section {
    width: 100%;
    overflow: hidden;
  }
  .openMenubtn {
    display: block;
    background: #efefef;
    position: sticky;
    top: 0;
    z-index: 1111;
  }
}

.user-icon{
    background-color: #7d9ffc;
    padding: 10px;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 800;
    color: #FFFFFF;
    cursor: pointer;
    flex-direction: column;
}

.user-detail-link{
  font-size: 10px;
  color: #2185D0;
}
.profile-user-icon{
  background-color: #6c87cf;
  width: 40px;
  height: 40px;
  margin: auto;
}

.organization-name{
    color: #1b1c1d;
    font-size: 14px;
    font-family: proxima-nova,sans-serif;
    font-weight: 700;
    border: 1px solid #b5b5b5;
    border-top: 0;
    border-bottom: 0;
    border-left: 0;
    margin: 0 20px 0px 10px;
    padding-right: 20px;
}
.profile-organization-name{
    border: 0px;
    padding: 0;
    margin: 0;
    margin-top: .8rem;

}
.divider-line{
  width: 150px;
  margin: .9rem auto;
  border-width: 2px;
}
.user-section-wrapper{    
  align-items: center;
  display: flex;
  justify-content: center;
}
.user-section{
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 10px;
}
.user-name{
  color:#1B1C1D;
  font-size: 14px;
  margin: 0;
}
.user-mail{
  color:#5E5F60;
  font-size: 12px;
  margin: 0;
}
.user-position{
  font-weight: bold;
  margin: 0;
}
.mobile-nav-item{
  background-color: #222838;
  color:#FFFFFF;
  min-height: 70px;
  border-radius: 10px;
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: center;
}
</style>
